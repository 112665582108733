// Feature detection library for Vault access
// This is split from the main bundle to ensure the feature detection works
// even if the main bundle fails to parse / execute.

// BrowserVendor represents a major browser vendor, not necessarily that such
// vendor is of interest to the code.
enum BrowserVendor {
    InternetExplorer,
    Edge,
    Firefox,
    Chrome,
    Safari,
    Unknown,
}

// BrowserInformation is the union of a Vendor + Version.
interface IBrowserInformation {
    vendor: BrowserVendor;
    version: string;
}

/**
 * isBrowserSupported decides whether the user's browser is one that Cydar
 * actively supports. That is, old and new functionality must work if this
 * returns true.
 * 
 * @returns whether browser is supported
 */
function isBrowserSupported(): boolean {
    const browser = getBrowserInformation(navigator.userAgent);
    // filter out old InternetExplorer:
    if (browser.vendor === BrowserVendor.InternetExplorer) {
        // not lower than IE11 (which is the final release)
        if (browser.version !== "11") {
            return false;
        }
    }
    return true;
}

// intercept page load to show warning if using unsupported browser, and hide
// aspects of site that are not usable if underlying tech is missing.
function checkCompatibility() {
    if (!isBrowserSupported()) {
        showUnsupportedBanner();
    }
    if (!supportsWebsockets()) {
        disableFeature("websockets");
    }
    if (!supportsWebGL()) {
        disableFeature("webgl")
    }
}

if (document.addEventListener) {
    document.addEventListener("DOMContentLoaded", checkCompatibility);
} else {
    window.onload = checkCompatibility;
}

/**
 * disableFeature removes DOM elements that should be shown only if such
 * feature is supported, and shows the DOM elements that should only be shown
 * if such feature is unsupported.
 * 
 * @param selectorFeature part of the selector to use to show/hide elements
 */
function disableFeature(selectorFeature: string) {
    const elementsToHide = document.querySelectorAll(`.hide-if-no-${selectorFeature}`);
    // tslint:disable-next-line
    for (let elementIndex = 0; elementIndex < elementsToHide.length; elementIndex++) {
        const element = (elementsToHide[elementIndex] as HTMLElement);
        const parentNode = element.parentNode;
        if (parentNode) {
            parentNode.removeChild(element);
        }
        element.innerHTML = "";
    }
    const elementsToShow = document.querySelectorAll(`.show-if-no-${selectorFeature}`);
    // tslint:disable-next-line
    for (let elementIndex = 0; elementIndex < elementsToShow.length; elementIndex++) {
        const element = (elementsToShow[elementIndex] as HTMLElement);
        element.style.display = "block";
        element.title = "This feature is not unsupported on the web browser in use. Please upgrade for a better experience.";
    }

}

/**
 * showUnsupportedBanner shows the obvious "unsupported browser detected" element.
 */
function showUnsupportedBanner() {
    const banner = document.querySelector("#unsupported-banner");
    if (!banner) {
        console.log("showUnsupportedBanner(): unable to find dom element");
        return;
    }
    (banner as HTMLElement).style.display = "block";
}

/**
 * getBrowserInformation identifies the current browser being used.
 * 
 * @param agentString agent string to parse
 */
function getBrowserInformation(agentString: string): IBrowserInformation {
    const information: IBrowserInformation = {
        vendor: getBrowserVendor(agentString),
        version: "?",
    }
    switch (information.vendor) {
        case BrowserVendor.InternetExplorer:
            information.version = getInternetExplorerVersion(agentString);
        break;
        case BrowserVendor.Edge:
            information.version = getEdgeVersion(agentString);
        break;
        case BrowserVendor.Firefox:
            information.version = getFirefoxVersion(agentString);
        break;
        case BrowserVendor.Chrome:
            information.version = getChromeVersion(agentString);
        break;
        case BrowserVendor.Safari:
            information.version = getSafariVersion(agentString);
        break;
    }
    return information;
}

/**
 * getBrowserVendor identifies the vendor of the browser being used, for
 * instance BrowserVersion.InternetExplorer if a version of IE is in use.
 * NOTE: Variants are groups together if their core engine is similar enough,
 * ie: Chromium is identifies as Chrome; this is for simplicity.
 * @param agentString agent string to parse
 */
function getBrowserVendor(agentString: string): BrowserVendor {
    if (agentString.indexOf("MSIE ") >= 0 || agentString.indexOf("Trident/") >= 0) {
        return BrowserVendor.InternetExplorer;
    }
    if (agentString.indexOf("Edge/") >= 0) {
        return BrowserVendor.Edge;
    }
    if (agentString.indexOf("Firefox/") >= 0) {
        return BrowserVendor.Firefox;
    }
    if (agentString.indexOf("Chrome/") >= 0) {
        return BrowserVendor.Chrome;
    }
    if (agentString.indexOf("AppleWebKit/") >= 0) {
        return BrowserVendor.Safari;
    }
    return BrowserVendor.Unknown;
}

/**
 * getInternetExplorerVersion identifies the version of Internet Explorer
 * being used, as reflected by the user agent.
 * @param agentString agent string to parse
 */
function getInternetExplorerVersion(agentString: string): string {
    // can be either:   "  rv:NN.N  " or "  MSIE N.N;  "
    let versionArray = /rv:([0-9]+)/.exec(agentString);
    if (versionArray && versionArray.length === 2) {
        return versionArray[1];
    }
    versionArray = /MSIE ([0-9]+)/.exec(agentString);
    if (versionArray && versionArray.length === 2) {
        return versionArray[1];
    }
    return "?";
}

/**
 * getEdgeVersion identifies the version of Microsoft Edge being used, as
 * reflected by the user agent.
 * @param agentString agent string to parse
 */
function getEdgeVersion(agentString: string): string {
    const versionArray = /Edge\/([0-9\.]+)/.exec(agentString);
    if (versionArray && versionArray.length === 2) {
        return versionArray[1];
    }
    return "?";
}

/**
 * getFirefoxVersion identifies the version of Mozilla Firefox being used, as
 * reflected by the user agent.
 * @param agentString agent string to parse
 */
function getFirefoxVersion(agentString: string): string {
    const versionArray = /Firefox\/([0-9\.]+)/.exec(agentString);
    if (versionArray && versionArray.length === 2) {
        return versionArray[1];
    }
    return "?";
}

/**
 * getChromeersion identifies the version of Google Chrome being used, as
 * reflected by the user agent.
 * NOTE: Variants of Google Chrome (IE: Chromium, new Edge) are suitable as
 * inputs to this function.
 * @param agentString agent string to parse
 */
function getChromeVersion(agentString: string): string {
    const versionArray = /Chrome\/([0-9\.]+)/.exec(agentString);
    if (versionArray && versionArray.length === 2) {
        return versionArray[1];
    }
    return "?";
}

/**
 * getSafariVersion identifies the version of Apple Safari being used, as
 * reflected by the user agent.
 * @param agentString agent string to parse
 */
function getSafariVersion(agentString: string): string {
    const versionArray = /Version\/([0-9\.]+)/.exec(agentString);
    if (versionArray && versionArray.length === 2) {
        return versionArray[1];
    }
    return "?";
}

/**
 * supportsWebsockets determines whether the current browser supports
 * Websockets.
 */
function supportsWebsockets(): boolean {
    return typeof WebSocket !== "undefined";
}

/**
 * supportsWebGL determines whether the current browser supports WebGL.
 * It is a relatively expensive function, so use sparingly.
 */
function supportsWebGL(): boolean {
    try {
        const tmpCanvas = document.createElement("canvas");
        const tmpContext = tmpCanvas.getContext("webgl") || tmpCanvas.getContext("experimental-webgl");
        if (tmpContext && tmpContext instanceof WebGLRenderingContext) {
            return true;
        }
    } catch (ex) {
        return false;
    }
    return false;
}
